// import { createI18n } from 'vue-i18n'
/**
 * Load locale messages
 * 
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
// function loadLocaleMessages() {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   console.log(messages)
//   return messages
// }

// export default createI18n({
//   legacy: false,
//   globalInjection: true,
//   locale: process.env.VUE_APP_I18N_LOCALE || 'id',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
//   messages: loadLocaleMessages()
// })

import { createI18n } from 'vue-i18n'
import id from '@/locales/id.json'
import en from '@/locales/en.json'

const pluralizationRules ={
  /**
   * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
   * @param choicesLength {number} an overall amount of available choices
   * @returns a final choice index to select plural word by
   */
  'id': (choice) =>{
    // this === VueI18n instance, so the locale property also exists here

    if (choice === 0) {
      return 0;
    }else{
      return 1;
    }
  }
}

const dateTimeFormats = {
  'en': {
    short: { 
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }
  },
  'id': {
    short: { 
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }
  }
}

const numberFormats = {
  'en': {
    currency: { 
      style: 'currency',
      currency: 'USD'
    }
  },
  'id': {
    currency: { 
      style: 'currency',
      currency: 'IDR'
    }
  }
}

export default createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'id',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: { id, en },
  pluralizationRules,
  dateTimeFormats,
  numberFormats
})
