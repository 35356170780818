const initItem =  {
    doctor: null,
    day: null,
    location: null,
    specialist: null,
    hospitals: []
  }
const initialState = () => ({
    searchDataDisplay: {...initItem},
    searchData: {...initItem},
    location_id: null
})
const state = initialState
 
const getters = {
    getSearchDataDisplay: state => state.searchDataDisplay,
    getSearchData: state => state.searchData,
    getLocationId: state => state.location_id,
}

const actions = {
    setLocationId({commit}, value){
        commit('SET_LOCATION_ID', value)
    },
    setSearchDataDisplay({commit, state}, {variable, value}){
        let payload = {
          ...state.searchDataDisplay
        }
        payload[variable] = value
        commit('SET_SEARCH_DATA_DISPLAY', payload)
    },
    setSearchData({commit, state}, {variable, value}){
        let payload = {
          ...state.searchData
        }
        payload[variable] = value
        commit('SET_SEARCH_DATA', payload)
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_SEARCH_DATA_DISPLAY(state, value){
        state.searchDataDisplay = {...value}
    },
    SET_SEARCH_DATA(state, value){
        state.searchData = {...value}
    },
    SET_LOCATION_ID(state, value){
        state.location_id = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}