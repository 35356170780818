<template>
  <div class="r-normal-input">
      <label v-if="label" :for="label">{{label}}</label>
      <div :class="inputClass()">
        <slot name="leftIcon"></slot>
        <input 
          :type="type"  
          :value="modelValue" 
          @input="onChange($event)" 
          :name="label" 
          class="r-isExpand" 
          :style="paddingStyle()"
          :placeholder="placeholder" 
          @change="change"
          @focus="onFocus"
          @blur="onBlur"
          @keypress="onKeypress"
          @keydown="onKeydown"
          @keyup="onKeyup"
          @keyup.enter="onKeyupEnter"
          @keypress.enter="onKeypressEnter"
          @keyup.tab="onKeyupTab"
          @keydown.tab="onKeydownTab"
          :readonly="readonly"
          :disabled="disabled"
          >
        <slot name="rightIcon"></slot>
      </div>
    <slot name="topErrorText"></slot>
    <p class="r-error-text r-isBold r-12-fz r-mb-4 " :class="error ? '' : 'r-opacity-0'" v-if="withError">{{errorMsg}}</p>
    <slot name="bottomErrorText"></slot>
  </div>
</template>
<script>
export default {
  emits: ['onFocus','update:modelValue',
    'blur','keypress',
    'keyup','keydown',
    'keypress.enter','keyup.enter','change',
    'keydown.tab','keyup.tab'],
  props: {
    modelValue: {
      required: true
    },
    label: {
      default: null
    },
    error: {
      default: false
    },
    errorMsg: {
      default: 'Tidak Boleh Kosong'
    },
    rightIcon: {
      default: false
    },
    leftIcon: {
      default: false
    },
    rightPadding: {
      default: 38
    },
    leftPadding: {
      default: 38
    },
    type: {
      default: 'text'
    },
    withError: {
      default: true
    },
    placeholder: {
      default: null
    },
    readonly: {
      default: false
    },
    disabled: {
      default: false
    },
  },
  methods: {
    inputClass(){
      if(this.rightIcon) return 'r-hasIcon-right'
      else if(this.leftIcon) return 'r-hasIcon-left'
      else return ''
    },
    paddingStyle(){
      if(this.rightIcon) return `padding-right: ${this.rightPadding}px !Important`
      else if(this.leftIcon) return `padding-left: ${this.leftPadding}px !Important`
      else return ''
    },
    onFocus(e){
      this.$emit('onFocus',e)
    },
    onChange(e){
      const value = e.target.value
      this.$emit('update:modelValue',value)
    },
    change(e){
      this.$emit('change',e)
    },
    onBlur(e){
      this.$emit('blur',e)
    },
    onKeypress(e){
      this.$emit('keypress',e)
    },
    onKeydown(e){
      this.$emit('keydown',e)
    },
    onKeyup(e){
      this.$emit('keyup',e)
    },
    onKeyupEnter(e){
      this.$emit('keyup.enter',e)
    },
    onKeypressEnter(e){
      this.$emit('keypress.enter',e)
    },
    onKeyupTab(e){
      this.$emit('keyup.tab',e)
    },
    onKeydownTab(e){
      this.$emit('keydown.tab',e)
    },
  }
}
</script>