
import { 
    postData, getData, destroyData
} from '@/store/config/method'

const requestInit = {
    page: 1,
    show: 10,
    sortBy: null,
    sorting: null,
    search: null,
    edu_level: 'Semua Unit',
    edu_level_id: null,
    status_label: 'Semua Status',
    status: null
}

const parentDetail = {
    parent_group_id: null,
    father_name: null,
    mother_name: null,
    wali_name: null,
    father_picture: null,
}

const itemInit = {
    id: null,
    name: null,
    nik: null,
    kk_number: null,
    akta_registration_number: null,
    religion_id: null,
    religion: null,
    gender: null,
    location_id: null,
    location: null,
    dob: null,
    phone_number: null,
    address: null,
    transportation_id: null,
    transportation: null,
    nisn: null,
    weight: null,
    height: null,
    is_using_kps_kph: false,
    is_had_kip: false,
    kip_number: null,
    edu_level_id: null,
    edu_level: null,
    nis: null,
    email: null,
    child_order: 1,
    ...parentDetail
}
const initialState = () => ({
    message: null,
    status: 0,
    meta: {},
    data: [],
    request: {...requestInit},
    item: {...itemInit},
    list: [],
    currentParent: {...parentDetail}
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getData: state => state.data,
    getMeta: state => state.meta,
    getRequest: state => state.request,
    getItem: state => state.item,
    getList: state => state.list
}

const actions = {
    resetRequest({commit}){
        commit('SET_REQUEST',{...requestInit})
    },
    resetItem({commit, state}){
        const temp = {
            ...itemInit,
            ...state.currentParent
        }
        commit('SET_ITEM',{...temp})
    },
    setItem({commit}, value){
        commit('SET_ITEM',{...value})
    },
    async store({
        commit
    }, data) {
        try {
            const payload = {...data}
            delete payload.id
            const response = await postData('api','/management/student',payload);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async fetchData({
        commit, state
    }){
        try {
            const response = await getData('api','/management/student',{params: state.request});
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getDetail({
        commit
    }, user_id){
        try {
            const url = `/management/student/${user_id}`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_ITEM', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async update({
        commit
    }, data) {
        try {
            const url = `/management/student/${data.id}`
            const response = await postData('api',url,data);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async destroy({
        commit
    }, data) {
        try {
            const url = `/management/student/${data.id}`
            const response = await destroyData('api',url);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status = error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status = response.status
    },
    SET_DATA(state, response) {
        state.data = response.data
        state.meta = {
            current_page: response.current_page,
            from: response.from,
            last_page: response.last_page,
            per_page: response.per_page,
            to: response.to,
            total: response.total
        }
    },
    SET_LIST(state, value) {
        state.list = value
    },
    SET_REQUEST(state, value){
        state.request = value
    },
    SET_ITEM(state, value){
        state.item = value
    },
    SET_FATHER_DATA(state, value){
        state.currentParent = value || {...parentDetail}
        const parentData = {
            ...state.item,
            ...state.currentParent
        }
        state.item = parentData
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}