import authRoute from './authRoute'
import mainRoute from './mainRoute'
import NotFound from '@/views/NotFound'
import { routerFilter } from '@/helpers/routes'
import dashboardLayout from '@/components/dashboard/Layout'

const generateRoutes = async () => {
  const auth = await authRoute;
  const main = await mainRoute;

  const routes = [
    {
      path: '', 
      meta: {
        requiresAuth: 1,
        role: []
      },
      component: dashboardLayout,
      children: [
        ...main,
      ]
    },
    ...auth,
    {
      path: '/:pathMatch(.*)*', 
      name: 'Not Found',
      meta: {
        requiresAuth: 2,
        role: []
      },
      component: NotFound
    },
    {
      path: '/:pathMatch(.*)', 
      name: 'Bad Not Found',
      meta: {
        requiresAuth: 2,
        role: []
      },
      component: NotFound
    }
  ]

  return await routerFilter(routes)
}
export default generateRoutes()