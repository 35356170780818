import store from "@/store"
import localForage from 'localforage';
import { diffDateTime } from './timeformat'

const routerFilter = async (routes) => {
  const temp = await localForage.getItem("auth")
  const myrole = temp?.auth?.user?.role
  const isLoggedIn = store.getters['getisLoggedIn']
  return routes.filter((row) => {
    return (isLoggedIn && row.meta.requiresAuth == 1 && (row.meta.role.some(role => role == myrole) || row.meta.role.length == 0)) || (row.meta.requiresAuth == 0 && !isLoggedIn) || row.meta.requiresAuth == 2
  })
}
const routeMiddleware = async (to, from, next) =>{
  await store.restored
  let created_at = store.getters['getCreatedAt']
  if(!created_at){
    created_at = new Date()
    store.dispatch('setCreatedAt',created_at)
  }
  if(store.getters['getisLoggedIn'] && diffDateTime(created_at, new Date()) > 1) 
  {
    store.dispatch('setCreatedAt',null)
    await store.dispatch('auth/getMyAccount')
    const isInvalid = store.getters[`auth/getIsInvalid`]
    if(isInvalid){ 
      await store.dispatch('auth/logoutUser',null);
      store.dispatch("logoutStorage");
      return location.reload()
    }
  }
  return validateUser(to, next)
}
const validateUser = (to, next) => {
  
  if (to.meta.requiresAuth == 2 && !store.getters['getisLoggedIn']) {
      return next({
        name: 'Login'
      })
  }
  if (to.meta.requiresAuth == 0 && store.getters['getisLoggedIn']) {
      return next({
          name: 'Dashboard'
      })
  }
  next()
}

//lazyload
const loadAuth = (view) => {
  return () => import( 
    /* webpackChunkName: "auth-[request]" */ 
    /* webpackMode: "lazy" */
    `@/views/auth/${view}.vue`)
}
const loadDashboard = (view) => {
  return () => import( 
    /* webpackChunkName: "dashboard-[request]" */ 
    /* webpackMode: "lazy" */
    `@/views/dashboard/${view}.vue`)
}

const loadSchool = (view) => {
  return () => import( 
    /* webpackChunkName: "school-[request]" */ 
    /* webpackMode: "lazy" */
    `@/views/school/${view}.vue`)
}
const loadWebsite = (view) => {
  return () => import( 
    /* webpackChunkName: "website-[request]" */ 
    /* webpackMode: "lazy" */
    `@/views/website/${view}.vue`)
}

const loadTeacher = (view) => {
  return () => import( 
    /* webpackChunkName: "teacher-[request]" */ 
    /* webpackMode: "lazy" */
    `@/views/teacher/${view}.vue`)
}

export {
  routerFilter,
  routeMiddleware,
  loadAuth,
  loadDashboard,
  loadSchool,
  loadWebsite,
  loadTeacher
}