
import { 
    postData, getData, destroyData
} from '@/store/config/method'

const requestInit = {
    page: 1,
    show: 10,
    sortBy: null,
    sorting: null,
    search: null,
}
const itemInit = {
    id: null,
    category_id : null,
    category: null,
    file_id : null,
    edu_level_id : null,
    edu_level: null,
    title : null,
    short_desc : null,
    content : '<p></p>',
    type : null,
    is_active : true,
    view: 'PUBLIC'
}
const initialState = () => ({
    message: null,
    status: 0,
    meta: {},
    data: [],
    request: {...requestInit},
    item: {...itemInit},
    list: [],
    disc: [
        {
            type: 'pengumuman',
            filter: 'ANNOUNCEMENT'
        },
        {
            type: 'blog',
            filter: 'BLOG'
        },
        {
            type: 'prestasi',
            filter: 'ACHIEVEMENT'
        },
    ]
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getData: state => state.data || [],
    getMeta: state => state.meta,
    getRequest: state => state.request,
    getItem: state => state.item,
    getList: state => state.list
}

const actions = {
    resetRequest({commit}){
        commit('SET_REQUEST',{...requestInit})
    },
    resetItem({commit}){
        commit('SET_ITEM',{...itemInit})
    },
    setItem({commit}, value){
        commit('SET_ITEM',{...value})
    },
    async store({
        commit
    }, payload) {
        try {
            delete payload.id
            const response = await postData('api','/management/article',payload);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async fetchData({
        commit, state
    }, article_type){
        try {
            const filter = state.disc.find(x => x.type == article_type)?.filter || 'ACHIEVEMENT'
            const response = await getData('api','/management/article',{params: {
                ...state.request,
                filter
            }});
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async validateSlug({
        commit
    }, slug){
        try {
            const url = `/article/validate/${slug}`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getDetail({
        commit
    }, unit_id){
        try {
            const url = `/management/article/${unit_id}`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_ITEM', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async update({
        commit
    }, payload) {
        try {
            const url = `/management/article/${payload.id}`
            delete payload.id
            const response = await postData('api',url,payload);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async destroy({
        commit
    }, data) {
        try {
            const url = `/management/article/${data.id}`
            const response = await destroyData('api',url);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status = error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status = response.status
    },
    SET_DATA(state, response) {
        state.data = response.data
        state.meta = {
            current_page: response.current_page,
            from: response.from,
            last_page: response.last_page,
            per_page: response.per_page,
            to: response.to,
            total: response.total
        }
    },
    SET_LIST(state, value) {
        state.list = value
    },
    SET_REQUEST(state, value){
        state.request = value
    },
    SET_ITEM(state, value){
        state.item = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}