import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import '@/style/main.scss'
import 'material-icons/iconfont/material-icons.css';

const runVue = async (r) => {
    const router = await r
    createApp(App)
        .use(i18n)
        .use(store)
        .use(router)
        .mount('#app')
}
runVue(router)