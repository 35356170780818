
import { 
    postData, getData
} from '@/store/config/method'

const userDataItemInit = {
    name: null,
    email: null,
}
const initialState = () => ({
    message: null,
    status: 0,
    user: {
        id: null,
        name: null,
        username: null,
        created_at: null,
    },
    token: null,
    schkey: null,
    otp: null,
    isInvalid: false ,
    userDataItem: {
        ...userDataItemInit
    }
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getUser: state => state.user,
    getToken: state => state.token,
    getSchKey: state => state.schkey,
    getOtp: state => state.otp,
    getIsInvalid: state => state.isInvalid,
    getUserDataItem: state => state.userDataItem
}

function makeid() {
    let result           = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < 20; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const actions = {
    setUserDataItem({commit}, value){
        commit('SET_USER_DATA_ITEM', {...value})
    },
    resetUserDataItem({commit}){
        commit('SET_USER_DATA_ITEM', {...userDataItemInit})
    },
    async login({
        commit
    }, data) {
        try {
            const response = await postData('api','/management/login',data);
            commit('SET_LOGIN_DATA', response.data)
        } catch (_) {
            commit('SET_FAILED_LOGIN');
        }
    },
    async logoutUser({
        commit
    }, data) {
        try {
            const response = await postData('api','/logout',data);
            commit('SET_RESPONSE', response.data);
            commit('SET_ISINVALID', false);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async verifyUser({
        commit
    }, token) {
        try {
            const response = await postData('api','/management/verify/account',{token});
            commit('SET_RESPONSE', response.data);
            commit('SET_OTP', response.data.data)
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async changePassword({
        commit
    }, data) {
        try {
            const response = await postData('api','/change-password',data);
            commit('SET_RESPONSE', response.data);
            commit('SET_USER_VERSION');
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async changeDataAdmin({
        commit
    }, data) {
        try {
            const response = await postData('api','/change-data-admin',data);
            commit('SET_RESPONSE', response.data);
            commit('SET_USER_DATA', data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async registerParent({
        commit
    }, data) {
        try {
            const response = await postData('api','/management/register/parent',data);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async updatePassword({
        commit, state
    }, {data, token}) {
        try {
            const payload = {
                token,
                password: data.password,
                otp: state.otp
            }
            const response = await postData('api','/management/update-password',payload);
            commit('SET_LOGIN_DATA', response.data)
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async forgetPassword({
        commit
    }, data) {
        try {
            const response = await postData('api','/management/forget-password',data);
            commit('SET_RESPONSE', response.data)
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getMyAccount({
        commit
    }) {
        try {
            const response = await getData('api','/management/my-account');
            commit('SET_RESPONSE', response.data)
            commit('SET_USER', response.data.data)
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status =  error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status =  response.status
    },
    SET_FAILED_LOGIN(state) {
        state.message = 'Username atau Password Anda Salah'
        state.status = 0
    },
    SET_OTP(state, value) {
        state.otp = value.otp
    },
    SET_ISINVALID(state, value) {
        state.isInvalid = value
    },
    SET_USER_DATA_ITEM(state, value) {
        state.userDataItem = value
    },
    SET_USER_DATA(state, data) {
        state.user.name = data.name
        state.user.email = data.email
    },
    SET_USER_VERSION(state) {
        state.user.version = parseInt(state.user.version) + 1
    },
    SET_LOGIN_DATA(state, response){
        state.message = response.message
        state.status = response.status
        state.user = response.user;
        state.isInvalid = false
        state.token = response.access_token
        const key = makeid()
        state.schkey = key
        localStorage.setItem('schkey',key)
    },
    SET_USER(state, value){
        if(value.status != 1 || state.user.version != value.version) {
            state.isInvalid = true
            return
        }
        state.user = value;
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}