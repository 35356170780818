<template>
<div class="r-siakad-header">
    Selamat Datang di SIAKAD Sekolah Masehi
</div>
<div class="r-flex">
    <sidebar class="r-sidebar-show"></sidebar>
    <div class="r-content">
        <router-view></router-view>
    </div>
</div>
</template>
<script>
import sidebar from '@/components/dashboard/Sidebar'

export default {
  components: {
    sidebar,
  },
  data: () => ({
      show: false,
      isMouseEnter: false
  }),
  methods: {
        toggleNavbar(){
            this.show = !this.show
            this.isMouseEnter = false
        },
        enterMouse(){
            if(!this.show && !this.isMouseEnter) {
                this.isMouseEnter = true
                this.show = true
            }
        },
        leaveMouse(){
            if(this.show && this.isMouseEnter) {
                this.isMouseEnter = false
                this.show = false
            }
        }
  }
}
</script>

