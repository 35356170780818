const initialState = () => ({
    breadcrumb: [],
})
const state = initialState
 
const getters = {
    getBreadcrumb: state => state.breadcrumb,
}

const actions = {
    resetBreadcrumb({
        commit
    }) {
        commit('SET_BREADCRUMB', []);
    },
    setBreadcrumb({
        commit
    }, value) {
        commit('SET_BREADCRUMB', value);
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_BREADCRUMB(state, value) {
        state.breadcrumb = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}