import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { routeMiddleware } from '@/helpers/routes'

const setRouter = async (routes) => {
  const data = await routes
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: data
  })
  router.beforeEach(routeMiddleware)
  return router
}
export default setRouter(routes)