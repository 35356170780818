import { routerFilter, loadDashboard } from '@/helpers/routes'
import schoolRoute from './dashboard/schoolRoute'
import otherRoute from './dashboard/otherRoute'
import websiteRoute from './dashboard/websiteRoute'
import teacherRoute from './dashboard/teacherRoute'

const routes = [
    {
        path: '',
        name: 'Dashboard',
        meta: {
            requiresAuth: 1,
            role: []
        },
        component: loadDashboard('Dashboard'),
    },
    ...schoolRoute,
    ...otherRoute,
    ...websiteRoute,
    ...teacherRoute
]

export default routerFilter(routes)