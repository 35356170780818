{
  "text": {
    "welcome": "Selamat Datang"
  },
  "metatag": {
    "default": {
      "title": "Judul Default",
      "description": "Deskripsi",
      "keywords": "default"
    }
  },
  "investor_relationship": {
    "sekilas-tentang-bisnis": "Sekilas Tentang Bisnis",
    "ikhtisar-keuangan": "Ikhtisar Keuangan",
    "harga-saham": "Harga Saham",
    "laporan-riset": "Laporan Riset",
    "informasi-investor": "Informasi Investor",
    "komposisi-pemegang-saham": "Komposisi Pemegang Saham",
    "struktur-saham": "Struktur Saham",
    "kebijakan-deviden": "Kebijakan Deviden",
    "riwayat-pembayaran-deviden": "Riwayat Pembayaran Deviden",
    "jadwal-dan-tata-cara": "Jadwal & Tata Cara (Deviden)",
    "prinsip-tata-kelola-perusahaan": "Prinsip Tata Kelola Perusahaan",
    "hak-pemegang-saham": "Hak Pemegang Saham",
    "perlakuan-yang-setara-dari-pemegang-saham": "Perlakuan yang setara dari Pemegang Saham",
    "kebijakan-whistleblower": "Kebijakan Whistleblower",
    "peraturan-perusahaan": "Peraturan Perusahaan",
    "kebijakan-keterbukaan-informasi": "Kebijakan Keterbukaan Informasi",
    "keterbukaan-informasi": "Keterbukaan Informasi",
    "struktur-kepemilikan": "Struktur Kepemilikan",
    "rapat-dewan-dan-komite": "Rapat Dewan & Komite",
    "struktur-dewan": "Struktur Dewan",
    "calendar-of-events": "Calendar of Events",
    "corporate-social-responsibility": "Corporate Social Responsibility",
    "kontak-hubungan-investor": "Kontak Hubungan Investor",
    "struktur-pemegang-saham": "Struktur Pemegang Saham",
    "pertemuan-pemegang-saham": "Pertemuan Pemegang Saham",
    "prinsip-tata-kelola-perusahaan-yang-sehat": "Prinsip Tata Kelola Perusahaan yang Sehat",
    "laporan-keuangan": "Laporan Keuangan",
    "laporan-tahunan": "Laporan Tahunan",
    "jumpa-pers": "Jumpa Pers",
    "laporan-keberlanjutan": "Laporan Keberlanjutan",
    "presentasi-perusahaan": "Presentasi Perusahaan",
    "prospektus": "Prospektus",
    "anggaran-dasar": "Anggaran Dasar",
    "piagam-dewan-dan-komite": "Piagam Dewan dan Komite"

  }
}