<template>
    <button :disabled="isLoading || disabled" :class="`r-${color}-${type}-btn r-ptb-${padding} ${widthClass}`">
        <template v-if="!isLoading">
            <slot></slot>
        </template>
        <img :src="require('@/assets/loadingDot.svg')" alt="loading" class="r-wd-50" v-else>
    </button>
</template>
<script>
export default {
    props: {
        color: {
            default: 'primary'
        },
        type: {
            default: 'filled'
        },
        padding: {
            default: 10
        },
        isLoading: {
            default: false
        },
        disabled: {
            default: false
        },
        widthClass: {
            default: 'r-wd-100-min'
        }
    }
}
</script>