
import { 
    postData, getData, destroyData
} from '@/store/config/method'

const requestInit = {
    page: 1,
    show: 10,
    sortBy: null,
    sorting: null,
    search: null
}
const itemInit = {
    id: null,
    file_id: null,
    edu_level_id: null,
}
const initialState = () => ({
    message: null,
    status: 0,
    meta: {},
    data: {
        paud: [],
        sd: [],
        smp: [],
        sma: [],
    },
    request: {...requestInit},
    metaWebsite: {},
    dataWebsite: [],
    requestWebsite: {...requestInit},
    item: {...itemInit},
    list: []
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getData: state => (type) => state.data[type],
    getMeta: state => (type) => state.meta[type],
    getRequest: state => (type) => state.request[type],
    getDataWebsite: state => state.dataWebsite || [],
    getMetaWebsite: state => state.metaWebsite,
    getRequestWebsite: state => state.requestWebsite,
    getItem: state => state.item,
    getList: state => state.list || []
}

const actions = {
    resetRequest({commit}){
        commit('SET_REQUEST',{...requestInit})
    },
    resetRequestWebsite({commit}){
        commit('SET_REQUEST_WEBSITE',{...requestInit})
    },
    resetItem({commit}){
        commit('SET_ITEM',{...itemInit})
    },
    setItem({commit}, value){
        commit('SET_ITEM',{...value})
    },
    async store({
        commit
    }, data) {
        try {
            const payload = {...data}
            delete payload.id
            const response = await postData('api','/management/facility',payload);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async fetchData({
        commit, state
    }, type = null){
        try {
            const url = '/management/facility' + (type == null ? '/website' : '')
            const response = await getData('api',url,{params: state.request});
            commit('SET_RESPONSE', response.data);
            if(type){ 
                commit('SET_DATA', {value: response.data.data, type});
            }else{
                commit('SET_DATA_WEBSITE', response.data.data);
            }
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async update({
        commit
    }, data) {
        try {
            const url = `/management/facility/${data.id}`
            const payload = {
                ...data
            }
            delete payload.id
            const response = await postData('api',url,payload);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async destroy({
        commit
    }, data) {
        try {
            const url = `/management/facility/${data.id}`
            const response = await destroyData('api',url);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status = error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status = response.status
    },
    SET_DATA(state, {value, type}) {
        state.data[type] = value
    },
    SET_DATA_WEBSITE(state, response) {
        state.dataWebsite = response
    },
    SET_LIST(state, value) {
        state.list = value
    },
    SET_REQUEST(state, value){
        state.request = value
    },
    SET_REQUEST_WEBSITE(state, value){
        state.requestWebsite = value
    },
    SET_ITEM(state, value){
        state.item = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}