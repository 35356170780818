
import { 
    postData, getData
} from '@/store/config/method'

const requestInit = {
    page: 1,
    show: 10,
    sortBy: null,
    sorting: null,
    search: null,
    gender_label: 'Semua Jenis Kelamin',
    gender: null,
}
const itemInit = {
    user_id: null,
    registration_number: null,
    status: null,
    name: null,
    gender: null,
    phone_number: null,
    nisn: null,
    admission_student_id: null,
    class: null,
    edu_level_name: null,
    short_name: null,
    school_year: null,
    nis: null,
    email: null,
}
const initialState = () => ({
    message: null,
    status: 0,
    meta: {},
    data: [],
    request: {...requestInit},
    item: {...itemInit},
    admissionSchedule: {},
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getData: state => state.data,
    getMeta: state => state.meta,
    getRequest: state => state.request,
    getItem: state => state.item,
    getAdmissionSchedule: state => state.admissionSchedule
}

const actions = {
    resetRequest({commit}){
        commit('SET_REQUEST',{...requestInit})
    },
    resetItem({commit}){
        commit('SET_ITEM',{...itemInit})
    },
    setItem({commit}, value){
        commit('SET_ITEM',{...value})
    },
    setEduLevelSlot({commit}, value){
        commit('SET_EDU_LEVEL_SLOT',value)
    },
    async store({
        commit
    }, payload) {
        try {
            delete payload.id
            const response = await postData('api','/management/admission-schedule',payload);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async fetchData({
        commit, state
    }, admission_schedule_id){
        try {
            const url = `/management/admission/${admission_schedule_id}`
            const response = await getData('api',url,{params: state.request});
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getAdmissionScheduleData({
        commit
    }, admission_schedule_id){
        try {
            const url = `/management/admission-schedule/${admission_schedule_id}`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_ADMISSION_SCHEDULE_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async update({
        commit
    }, payload) {
        try {
            const url = `/management/admission-schedule/${payload.id}`
            delete payload.id
            const response = await postData('api',url,payload);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async destroy({
        commit
    }, data) {
        try {
            const url = `/management/admission-student/update/reject`
            const response = await postData('api',url, data);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async accept({
        commit
    }, data) {
        try {
            const url = `/management/admission-student/update/accept`
            const response = await postData('api',url, data);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status = error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status = response.status
    },
    SET_DATA(state, response) {
        state.data = response.data
        state.meta = {
            current_page: response.current_page,
            from: response.from,
            last_page: response.last_page,
            per_page: response.per_page,
            to: response.to,
            total: response.total
        }
    },
    SET_REQUEST(state, value){
        state.request = value
    },
    SET_ITEM(state, value){
        state.item = value
    },
    SET_ADMISSION_SCHEDULE_DATA(state, value){
        state.admissionSchedule = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}