import { loadTeacher } from '@/helpers/routes'

const routes = [
    {
        path: 'kelas',
        name: 'ClassRoom',
        meta: {
            requiresAuth: 1,
            role: ['teacher']
        },
        component: loadTeacher('ClassRoom'),
    },
    {
        path: 'nilai-rapor',
        name: 'ClassRoomReport',
        meta: {
            requiresAuth: 1,
            role: ['teacher']
        },
        component: loadTeacher('ClassRoomReport'),
    },
    {
        path: 'nilai-rapor/:classroom_slot_id',
        name: 'ClassRoomReportDetail',
        meta: {
            requiresAuth: 1,
            role: ['teacher']
        },
        component: loadTeacher('ClassRoomReportDetail'),
    },
    {
        path: 'nilai-rapor/:classroom_slot_id/siswa/:user_id',
        name: 'ClassRoomReportDetailStudent',
        meta: {
            requiresAuth: 1,
            role: ['teacher']
        },
        component: loadTeacher('StudentDetail'),
    },
    {
        path: 'kelas/:classroom_subject_id',
        name: 'ClassRoomDetail',
        meta: {
            requiresAuth: 1,
            role: ['teacher']
        },
        component: loadTeacher('ClassRoomDetail'),
    },
    {
        path: 'kelas/:classroom_subject_id/siswa/:user_id',
        name: 'ClassRoomDetailStudent',
        meta: {
            requiresAuth: 1,
            role: ['teacher']
        },
        component: loadTeacher('StudentDetail'),
    },
]

export default routes